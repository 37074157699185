// 說明 (完整說明見) src/sass/all.sass
//	* 此檔案包含：Layout樣式 Loader、Header、Footer、Cookie、Breadcrumb

@import './layout/common';
/* Header */
header{
	text-align: center;
	z-index: 100;
	display: block;
    position: fixed;
	right: rem-calc(50);
	top: rem-calc(50);
	.grid-x{
		justify-content: flex-end;
	}
	a{
		width: rem-calc(140);
		height: rem-calc(91);
		position: relative;
		display: block;
		margin: 0 0;
		img{
			width: 100%;
		}
	}
	@include breakpoint-hover {
		&:hover,
		&:focus{
			opacity: 0.75;
			transition: all 0.5s;
		}
	}
	@include breakpoint(medium down){
		right: rem-calc(20);
		top: rem-calc(20);
		a{
			width: rem-calc(90);
			height: auto;
		}
		img{
			width: rem-calc(90);
		}
	}
}
// --------------------------------------------------------

/* Footer */
footer{
	--size: #{rem-calc(40)};
	--distance: #{rem-calc(60)};
	i{
		font-size: rem-calc(20);
	}
	p{
		color: $primary;
		padding: rem-calc(30 0);
	}
	.media{
		position: fixed;
		z-index: 50;
		width: var(--size);
		left: var(--distance);
		@extend %media;
		@include breakpoint(medium down){
			i{
				font-size: rem-calc(18);
			}
			--size: #{rem-calc(30)};
			--distance: #{rem-calc(15)};
			.media_btn{
				&:not(:last-child){
					margin-bottom: rem-calc(10);
				}
			}
		}
	}
	.warning{
		background: $white;
		color: $black;
		max-height: 10%;
		height: auto;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 2.5vw;
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 1010;
		width: 100%;
		padding: 1vh 0;
		font-weight: 400;
		img{
			margin-right: rem-calc(20);
			margin-left: rem-calc(5);
		}
			
		@include breakpoint(medium down){
			font-size: 5vw;
			img{
				width: rem-calc(20);
			}
		}
	}
	
}

