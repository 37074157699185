// 水平 Padding (Medium+ 避免左右寬度過窄)
.grid-container {
	// --pd-x: #{rem-calc(16)}; // 設定於:root
	padding-right: var(--pd-x);
	padding-left: var(--pd-x);
}
.sec{
	&:not(#sectionPin) {
		// min-height: calc(100vh - 10%);
		position: relative;
	}
}