@mixin font-icon {
	@font-face {
		font-family: "icon";
		src: url('../fonts/icons/icon.eot');
		src: url('../fonts/icons/icon.eot?#iefix') format('embedded-opentype'),
		url('../fonts/icons/icon.woff2') format('woff2'),
		url('../fonts/icons/icon.woff') format('woff'),
		url('../fonts/icons/icon.ttf') format('truetype'),
		url('../fonts/icons/icon.svg#icon') format('svg');
		font-display: block;
		unicode-range: U+EA01-EA04;
	}

	.be-icon {
		font-family: sans-serif;
		line-height: 1;
	}

	.be-icon:before {
		@include font-icon-style;
	}
	
	/* Glyphs list */
	
	.be-icon-fb:before {
		content: '\ea01';
	}
	
	.be-icon-ig:before {
		content: '\ea02';
	}
	
	.be-icon-line:before {
		content: '\ea03';
	}
	
	.be-icon-link:before {
		content: '\ea04';
	}
	
}

// <i>:before Style
@mixin font-icon-style {
	font-family: "icon";
	
	// Font smoothing. That was taken from TWBS
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	font-style: normal;
	font-weight: normal;

	// For safety - reset parent styles, that can break glyph codes
	font-variant: normal;
	text-transform: none;
	text-decoration: none;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	// only necessary if not using the private unicode range (firstGlyph option)
	speak: none;

	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	margin-right: .2em;
	text-align: center;

	// fix buttons height, for twitter bootstrap
	line-height: 1em;

	// Animation center compensation - margins should be symmetric
	// remove if not needed
	margin-left: .2em;

	// you can be more comfortable with increased icons size
	// font-size: 120%;

	// Uncomment for 3D effect
	// text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3);
}

// @include font-icon;

// SASS變數使用範例
// .icon {
//     &:before{
// 		@include font-icon-add(SVG檔案名稱);
//     }
// }

// 內頁使用之Style
%font-icon-style {
	@include font-icon-style;
}


$font-icon-fb: '\ea01';

$font-icon-ig: '\ea02';

$font-icon-line: '\ea03';

$font-icon-link: '\ea04';


// 可以帶入變數
@mixin font-icon-add($icon: '', $extendStyle: true) {
	
	@if $icon == fb {
		content: '\ea01';
	}
	
	@if $icon == ig {
		content: '\ea02';
	}
	
	@if $icon == line {
		content: '\ea03';
	}
	
	@if $icon == link {
		content: '\ea04';
	}
	

	@if $extendStyle == true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}