// ★內頁 不需要 @include style.sass(layout)已經有
// 說明 (完整說明見) src/sass/style.sass
//	* 此檔案包含：內頁共用元件
//		- HTML Tag
//		- 表單(Form)相關元件
//		- 修改框架/套件
//		- keyframes

// --------------------------------------------------------

/* Tag */

html,
body {
	overflow-x: hidden;
	overflow-y: auto;
	background-color: $black;
	// overscroll-behavior: none;
}

img {
	pointer-events: none;
}

a,
button,
.button {
	&:focus {
		outline: none;
	}
}

// a,
// p {
// 	// font-weight: $global-weight-normal;
// 	@include breakpoint(medium down) {
// 		font-size: rem-calc(14);
// 	}
// }

h1{
	color: $white;
	font-weight: 400;
	margin-bottom: rem-calc(80);
	&.dark{
		color: rgba($dark-color, 0.4);
	}
	@include breakpoint(medium down) {
		margin-bottom: rem-calc(30);
	}
}
h5{
	color: $third;
	margin-bottom: rem-calc(26);
	font-weight: 400;
	@include breakpoint(medium down) {
		margin-bottom: rem-calc(20);
	}
}
p{
	color: $primary;
	font-weight: normal;
}

// --------------------------------------------------------

/* 反選顏色 | Selection */
$selection-text-color: $white; // 反選的文字顏色
$selection-bg-color: darken($primary-color, 5%); // 反選的背景顏色

@mixin selection-color($text-color, $bg-color) {
	::selection {
		color: $text-color;
		background: $bg-color;

		// 當瀏覽器Tab非Focus狀態
		&:window-inactive {
			color: #323232;
			background: #c5c5c5;
		}
	}

	// Firefox版本: https://css-tricks.com/window-inactive-styling/#comment-92675
	// 但因為gulp-ruby-sass看不懂導致編譯錯誤(Invalid CSS)，所以無法使用
	// :-moz-window-inactive ::-moz-selection
	// 	color: #323232
	// 	background: #C5C5C5
}

// No Selection | 無法反選
// https://stackoverflow.com/a/4407335/11240898
@mixin user-select($val: none) {
	-webkit-touch-callout: $val; // iOS Safari
	-webkit-user-select: $val; // Safari
	-khtml-user-select: $val; // Konqueror HTML
	-moz-user-select: $val; // Firefox
	-ms-user-select: $val; // Internet Explorer/Edge
	user-select: $val; // Non-prefixed version, currently, supported by Chrome and Opera
}

// +selection-color(反選的文字顏色, 反選的背景顏色)
@include selection-color($selection-text-color, $selection-bg-color);

// 無法反選 | No Selection
.no-selection {
	@include user-select(none);
}

// --------------------------------------------------------

// Custom Scroll Bar
// Mixin 移動到　src\sass\layout\_variable_mixin_function.scss
// 需使用則開啟下列樣式
@include breakpoint-hover {
	@include custom-scroll-bar;

	.custom-scrollbar {
		@include custom-scroll-bar;
	}
}

// --------------------------------------------------------

/* Text */

// --------------------------------------------------------

/* a Link */

// --------------------------------------------------------

/* Image */

.img-absolute {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* object-fit */
// Fix the dimensions of an image and scale
// Default size: 1:1
//
// How to change size
// $imgWidth: // img width
// $imgHeight: // img height
// .object-fit:before{padding-top: round($imgHeight / $imgWidth * 100%)}
// round: round the number rounding
%object-fit-standard {
	position: relative;
	display: block; // Fixed <picture> bug
	overflow: hidden;

	&::before {
		content: '';
		display: block;
		padding-top: 100%;
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100% !important;
		height: 100%;
		object-position: center center;
		background-position: center center;
	}
}

%object-fit-cover {
	img {
		object-fit: cover;
		background-size: cover;
	}
}

%object-fit-contain {
	background-position: center center;
	background-size: contain;

	img {
		object-fit: contain;
		background-size: contain;
	}
}

.object-fit {
	@extend %object-fit-standard;
	@extend %object-fit-cover;

	// 沒有內層(before)
	// &--wrap {
	// 	@extend %object-fit-standard;
	// 	@extend %object-fit-cover;

	// 	&::before {
	// 		display: none;
	// 	}
	// }

	// 應對不同比例的照片
	&--contain {
		@extend %object-fit-standard;
		@extend %object-fit-contain;
	}
}

// --------------------------------------------------------

/* Main Content */
.main-wrapper {
	position: relative;
	// overflow: hidden !important;
}

// --------------------------------------------------------

/* Help */


.mb-0 {
	margin-bottom: 0 !important;
}
.show-for-xxlarge{
	display: none;
	@media screen and (min-width: 1600px) {
		display: block;
	}
}