/* 中文襯線字 */
// 從 HTML 載入 Google Font 設定
.font-serif {
	font-family: 'Noto Sans TC', sans-serif;
}

// 草寫英文
.font-script {
	font-family: 'DM Serif Display', serif;
	// text-transform: uppercase;
}

.text-uppercase {
	text-transform: uppercase;
}